/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Moab Core API
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import type {
  BaseRepairCreateSchema,
  BaseRepairUpdateSchema,
  PaginatedProcedureSchema,
  PaginatedRepairSchema,
  ProcedureCreateSchema,
  ProcedureHydratedDumpSchema,
  ProcedureListParams,
  ProcedureUpdateSchema,
  RepairHydratedDumpSchema,
  RepairListParams,
  RepairWorkHistorySchema
} from '../moabCoreAPI.schemas'
import { kyusuAxiosMutator } from '../../../orval/mutator/axios-mutator';
import type { ErrorType, BodyType } from '../../../orval/mutator/axios-mutator';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const procedureList = (
    params?: ProcedureListParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedProcedureSchema>(
      {url: `/repair/procedures`, method: 'GET',
        params, signal
    },
      options);
    }


export const getProcedureListQueryKey = (params?: ProcedureListParams,) => {
    return [`/repair/procedures`, ...(params ? [params]: [])] as const;
    }


export const getProcedureListQueryOptions = <TData = Awaited<ReturnType<typeof procedureList>>, TError = ErrorType<unknown>>(params?: ProcedureListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof procedureList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getProcedureListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof procedureList>>> = ({ signal }) => procedureList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof procedureList>>, TError, TData> & { queryKey: QueryKey }
}

export type ProcedureListQueryResult = NonNullable<Awaited<ReturnType<typeof procedureList>>>
export type ProcedureListQueryError = ErrorType<unknown>


export function useProcedureList<TData = Awaited<ReturnType<typeof procedureList>>, TError = ErrorType<unknown>>(
 params: undefined |  ProcedureListParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof procedureList>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof procedureList>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useProcedureList<TData = Awaited<ReturnType<typeof procedureList>>, TError = ErrorType<unknown>>(
 params?: ProcedureListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof procedureList>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof procedureList>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useProcedureList<TData = Awaited<ReturnType<typeof procedureList>>, TError = ErrorType<unknown>>(
 params?: ProcedureListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof procedureList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useProcedureList<TData = Awaited<ReturnType<typeof procedureList>>, TError = ErrorType<unknown>>(
 params?: ProcedureListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof procedureList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getProcedureListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getProcedureListSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof procedureList>>, TError = ErrorType<unknown>>(params?: ProcedureListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof procedureList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getProcedureListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof procedureList>>> = ({ signal }) => procedureList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof procedureList>>, TError, TData> & { queryKey: QueryKey }
}

export type ProcedureListSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof procedureList>>>
export type ProcedureListSuspenseQueryError = ErrorType<unknown>


export function useProcedureListSuspense<TData = Awaited<ReturnType<typeof procedureList>>, TError = ErrorType<unknown>>(
 params: undefined |  ProcedureListParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof procedureList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useProcedureListSuspense<TData = Awaited<ReturnType<typeof procedureList>>, TError = ErrorType<unknown>>(
 params?: ProcedureListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof procedureList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useProcedureListSuspense<TData = Awaited<ReturnType<typeof procedureList>>, TError = ErrorType<unknown>>(
 params?: ProcedureListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof procedureList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useProcedureListSuspense<TData = Awaited<ReturnType<typeof procedureList>>, TError = ErrorType<unknown>>(
 params?: ProcedureListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof procedureList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getProcedureListSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const procedureCreate = (
    procedureCreateSchema: BodyType<ProcedureCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<ProcedureHydratedDumpSchema>(
      {url: `/repair/procedures`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: procedureCreateSchema
    },
      options);
    }


export const procedureGet = (
    procedureId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<ProcedureHydratedDumpSchema>(
      {url: `/repair/procedures/${procedureId}`, method: 'GET', signal
    },
      options);
    }


export const getProcedureGetQueryKey = (procedureId: string,) => {
    return [`/repair/procedures/${procedureId}`] as const;
    }


export const getProcedureGetQueryOptions = <TData = Awaited<ReturnType<typeof procedureGet>>, TError = ErrorType<unknown>>(procedureId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof procedureGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getProcedureGetQueryKey(procedureId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof procedureGet>>> = ({ signal }) => procedureGet(procedureId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(procedureId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof procedureGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ProcedureGetQueryResult = NonNullable<Awaited<ReturnType<typeof procedureGet>>>
export type ProcedureGetQueryError = ErrorType<unknown>


export function useProcedureGet<TData = Awaited<ReturnType<typeof procedureGet>>, TError = ErrorType<unknown>>(
 procedureId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof procedureGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof procedureGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useProcedureGet<TData = Awaited<ReturnType<typeof procedureGet>>, TError = ErrorType<unknown>>(
 procedureId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof procedureGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof procedureGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useProcedureGet<TData = Awaited<ReturnType<typeof procedureGet>>, TError = ErrorType<unknown>>(
 procedureId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof procedureGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useProcedureGet<TData = Awaited<ReturnType<typeof procedureGet>>, TError = ErrorType<unknown>>(
 procedureId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof procedureGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getProcedureGetQueryOptions(procedureId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getProcedureGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof procedureGet>>, TError = ErrorType<unknown>>(procedureId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof procedureGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getProcedureGetQueryKey(procedureId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof procedureGet>>> = ({ signal }) => procedureGet(procedureId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(procedureId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof procedureGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ProcedureGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof procedureGet>>>
export type ProcedureGetSuspenseQueryError = ErrorType<unknown>


export function useProcedureGetSuspense<TData = Awaited<ReturnType<typeof procedureGet>>, TError = ErrorType<unknown>>(
 procedureId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof procedureGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useProcedureGetSuspense<TData = Awaited<ReturnType<typeof procedureGet>>, TError = ErrorType<unknown>>(
 procedureId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof procedureGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useProcedureGetSuspense<TData = Awaited<ReturnType<typeof procedureGet>>, TError = ErrorType<unknown>>(
 procedureId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof procedureGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useProcedureGetSuspense<TData = Awaited<ReturnType<typeof procedureGet>>, TError = ErrorType<unknown>>(
 procedureId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof procedureGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getProcedureGetSuspenseQueryOptions(procedureId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const procedureUpdate = (
    procedureId: string,
    procedureUpdateSchema: BodyType<ProcedureUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<ProcedureHydratedDumpSchema>(
      {url: `/repair/procedures/${procedureId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: procedureUpdateSchema
    },
      options);
    }


export const repairList = (
    params?: RepairListParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedRepairSchema>(
      {url: `/repair/repairs`, method: 'GET',
        params, signal
    },
      options);
    }


export const getRepairListQueryKey = (params?: RepairListParams,) => {
    return [`/repair/repairs`, ...(params ? [params]: [])] as const;
    }


export const getRepairListQueryOptions = <TData = Awaited<ReturnType<typeof repairList>>, TError = ErrorType<unknown>>(params?: RepairListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof repairList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRepairListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof repairList>>> = ({ signal }) => repairList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof repairList>>, TError, TData> & { queryKey: QueryKey }
}

export type RepairListQueryResult = NonNullable<Awaited<ReturnType<typeof repairList>>>
export type RepairListQueryError = ErrorType<unknown>


export function useRepairList<TData = Awaited<ReturnType<typeof repairList>>, TError = ErrorType<unknown>>(
 params: undefined |  RepairListParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof repairList>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof repairList>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useRepairList<TData = Awaited<ReturnType<typeof repairList>>, TError = ErrorType<unknown>>(
 params?: RepairListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof repairList>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof repairList>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useRepairList<TData = Awaited<ReturnType<typeof repairList>>, TError = ErrorType<unknown>>(
 params?: RepairListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof repairList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useRepairList<TData = Awaited<ReturnType<typeof repairList>>, TError = ErrorType<unknown>>(
 params?: RepairListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof repairList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getRepairListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getRepairListSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof repairList>>, TError = ErrorType<unknown>>(params?: RepairListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof repairList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRepairListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof repairList>>> = ({ signal }) => repairList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof repairList>>, TError, TData> & { queryKey: QueryKey }
}

export type RepairListSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof repairList>>>
export type RepairListSuspenseQueryError = ErrorType<unknown>


export function useRepairListSuspense<TData = Awaited<ReturnType<typeof repairList>>, TError = ErrorType<unknown>>(
 params: undefined |  RepairListParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof repairList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useRepairListSuspense<TData = Awaited<ReturnType<typeof repairList>>, TError = ErrorType<unknown>>(
 params?: RepairListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof repairList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useRepairListSuspense<TData = Awaited<ReturnType<typeof repairList>>, TError = ErrorType<unknown>>(
 params?: RepairListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof repairList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useRepairListSuspense<TData = Awaited<ReturnType<typeof repairList>>, TError = ErrorType<unknown>>(
 params?: RepairListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof repairList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getRepairListSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const repairCreate = (
    baseRepairCreateSchema: BodyType<BaseRepairCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<RepairHydratedDumpSchema>(
      {url: `/repair/repairs`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: baseRepairCreateSchema
    },
      options);
    }


export const repairGet = (
    repairId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<RepairHydratedDumpSchema>(
      {url: `/repair/repairs/${repairId}`, method: 'GET', signal
    },
      options);
    }


export const getRepairGetQueryKey = (repairId: string,) => {
    return [`/repair/repairs/${repairId}`] as const;
    }


export const getRepairGetQueryOptions = <TData = Awaited<ReturnType<typeof repairGet>>, TError = ErrorType<unknown>>(repairId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof repairGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRepairGetQueryKey(repairId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof repairGet>>> = ({ signal }) => repairGet(repairId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(repairId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof repairGet>>, TError, TData> & { queryKey: QueryKey }
}

export type RepairGetQueryResult = NonNullable<Awaited<ReturnType<typeof repairGet>>>
export type RepairGetQueryError = ErrorType<unknown>


export function useRepairGet<TData = Awaited<ReturnType<typeof repairGet>>, TError = ErrorType<unknown>>(
 repairId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof repairGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof repairGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useRepairGet<TData = Awaited<ReturnType<typeof repairGet>>, TError = ErrorType<unknown>>(
 repairId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof repairGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof repairGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useRepairGet<TData = Awaited<ReturnType<typeof repairGet>>, TError = ErrorType<unknown>>(
 repairId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof repairGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useRepairGet<TData = Awaited<ReturnType<typeof repairGet>>, TError = ErrorType<unknown>>(
 repairId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof repairGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getRepairGetQueryOptions(repairId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getRepairGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof repairGet>>, TError = ErrorType<unknown>>(repairId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof repairGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRepairGetQueryKey(repairId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof repairGet>>> = ({ signal }) => repairGet(repairId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(repairId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof repairGet>>, TError, TData> & { queryKey: QueryKey }
}

export type RepairGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof repairGet>>>
export type RepairGetSuspenseQueryError = ErrorType<unknown>


export function useRepairGetSuspense<TData = Awaited<ReturnType<typeof repairGet>>, TError = ErrorType<unknown>>(
 repairId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof repairGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useRepairGetSuspense<TData = Awaited<ReturnType<typeof repairGet>>, TError = ErrorType<unknown>>(
 repairId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof repairGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useRepairGetSuspense<TData = Awaited<ReturnType<typeof repairGet>>, TError = ErrorType<unknown>>(
 repairId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof repairGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useRepairGetSuspense<TData = Awaited<ReturnType<typeof repairGet>>, TError = ErrorType<unknown>>(
 repairId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof repairGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getRepairGetSuspenseQueryOptions(repairId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const repairUpdate = (
    repairId: string,
    baseRepairUpdateSchema: BodyType<BaseRepairUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<RepairHydratedDumpSchema>(
      {url: `/repair/repairs/${repairId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: baseRepairUpdateSchema
    },
      options);
    }


export const getRepairHistory = (
    repairId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<RepairWorkHistorySchema>(
      {url: `/repair/repairs/${repairId}/work-history`, method: 'GET', signal
    },
      options);
    }


export const getGetRepairHistoryQueryKey = (repairId: string,) => {
    return [`/repair/repairs/${repairId}/work-history`] as const;
    }


export const getGetRepairHistoryQueryOptions = <TData = Awaited<ReturnType<typeof getRepairHistory>>, TError = ErrorType<unknown>>(repairId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRepairHistory>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRepairHistoryQueryKey(repairId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRepairHistory>>> = ({ signal }) => getRepairHistory(repairId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(repairId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRepairHistory>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRepairHistoryQueryResult = NonNullable<Awaited<ReturnType<typeof getRepairHistory>>>
export type GetRepairHistoryQueryError = ErrorType<unknown>


export function useGetRepairHistory<TData = Awaited<ReturnType<typeof getRepairHistory>>, TError = ErrorType<unknown>>(
 repairId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRepairHistory>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getRepairHistory>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetRepairHistory<TData = Awaited<ReturnType<typeof getRepairHistory>>, TError = ErrorType<unknown>>(
 repairId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRepairHistory>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getRepairHistory>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetRepairHistory<TData = Awaited<ReturnType<typeof getRepairHistory>>, TError = ErrorType<unknown>>(
 repairId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRepairHistory>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetRepairHistory<TData = Awaited<ReturnType<typeof getRepairHistory>>, TError = ErrorType<unknown>>(
 repairId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRepairHistory>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetRepairHistoryQueryOptions(repairId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetRepairHistorySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getRepairHistory>>, TError = ErrorType<unknown>>(repairId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getRepairHistory>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRepairHistoryQueryKey(repairId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRepairHistory>>> = ({ signal }) => getRepairHistory(repairId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(repairId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getRepairHistory>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRepairHistorySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getRepairHistory>>>
export type GetRepairHistorySuspenseQueryError = ErrorType<unknown>


export function useGetRepairHistorySuspense<TData = Awaited<ReturnType<typeof getRepairHistory>>, TError = ErrorType<unknown>>(
 repairId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getRepairHistory>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetRepairHistorySuspense<TData = Awaited<ReturnType<typeof getRepairHistory>>, TError = ErrorType<unknown>>(
 repairId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getRepairHistory>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetRepairHistorySuspense<TData = Awaited<ReturnType<typeof getRepairHistory>>, TError = ErrorType<unknown>>(
 repairId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getRepairHistory>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetRepairHistorySuspense<TData = Awaited<ReturnType<typeof getRepairHistory>>, TError = ErrorType<unknown>>(
 repairId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getRepairHistory>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetRepairHistorySuspenseQueryOptions(repairId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



