import {
  type AccountingActionStatus,
  type AssetStatus,
  type DeliveryRouteStatus,
  type DeliveryRouteStopStatus,
  type DepositStatus,
  type InvoiceStatus,
  type ItemReceiptStatus,
  type LineItemStatus,
  type OrderDumpSchema,
  type OrderStatus,
  type PaymentStatus,
  type PurchaseOrderStatus,
  type RepairStatus,
  type WarrantyStatus,
} from '_autogenerated'
import { renderEnumValue } from 'utils/enums'

import { type KuiBadgeColor, type KuiBadgeProps } from './kui'

const invoiceStatusToBadgeColor: Record<InvoiceStatus, KuiBadgeColor> = {
  scheduled: 'disabled',
  pending: 'accent',
  paid: 'constructive',
  voided: 'disabled',
  uncollectible: 'disabled',
  delinquent: 'destructive',
}

export function getInvoiceStatusBadgeProps({
  status,
}: {
  status: InvoiceStatus
}): Pick<KuiBadgeProps, 'color' | 'children'> {
  return {
    color: invoiceStatusToBadgeColor[status],
    children: renderEnumValue(status),
  }
}

const paymentStatusToBadgeColor: Record<PaymentStatus, KuiBadgeColor> = {
  pending: 'disabled',
  paid: 'constructive',
  failed: 'destructive',
  refunded: 'accent',
  partially_refunded: 'accent',
  voided: 'disabled',
}

export function getPaymentStatusBadgeProps({
  status,
}: {
  status: PaymentStatus
}): Pick<KuiBadgeProps, 'color' | 'children'> {
  return {
    color: paymentStatusToBadgeColor[status],
    children: renderEnumValue(status),
  }
}

const depositStatusToBadgeColor: Record<DepositStatus, KuiBadgeColor> = {
  pending: 'accent',
  paid: 'constructive',
  failed: 'destructive',
}

export function getDepositStatusBadgeProps({
  status,
}: {
  status: DepositStatus
}): Pick<KuiBadgeProps, 'color' | 'children'> {
  return {
    color: depositStatusToBadgeColor[status],
    children: renderEnumValue(status),
  }
}

const orderStatusToBadgeColor: Record<OrderStatus, KuiBadgeColor> = {
  quote: 'disabled',
  reservation: 'secondaryAccent',
  contract: 'tertiaryAccent',
  active: 'accent',
  off_rent: 'warning',
  returned: 'constructive',
  sold_closed: 'constructive',
  deleted: 'destructive',
}

export function getOrderStatusBadgeProps({
  status,
}: {
  status: OrderStatus
}): Pick<KuiBadgeProps, 'color' | 'children'> {
  return {
    color: orderStatusToBadgeColor[status],
    children: renderEnumValue(status),
  }
}

export function getOrderSignatureStatusBadgeProps(
  order: Pick<OrderDumpSchema, 'status' | 'signed_by' | 'signed_at'>
): Pick<KuiBadgeProps, 'color' | 'children'> {
  return {
    color: order.signed_by ? 'constructive' : 'disabled',
    children: order.signed_by ? 'Signed' : 'Not signed',
  }
}

const orderLineItemStatusProps: Record<
  LineItemStatus,
  {
    label: string
    color: KuiBadgeColor
  }
> = {
  order: {
    label: 'Needs assignment',
    color: 'disabled',
  },
  reserved: {
    label: 'Reserved',
    color: 'secondaryAccent',
  },
  on_rent: {
    label: 'On rent',
    color: 'accent',
  },
  off_rent: {
    label: 'Off rent',
    color: 'warning',
  },
  sold: {
    label: 'Sold',
    color: 'constructive',
  },
  returned: {
    label: 'Returned',
    color: 'constructive',
  },
}

export function getOrderLineItemStatusBadgeProps({
  status,
}: {
  status: LineItemStatus
}): Pick<KuiBadgeProps, 'color' | 'children'> {
  const { color, label } = orderLineItemStatusProps[status]

  return {
    color,
    children: label,
  }
}

const deliveryRouteStatusToBadgeColor: Record<
  DeliveryRouteStatus,
  KuiBadgeColor
> = {
  draft: 'disabled',
  scheduled: 'disabled',
  driver_notified: 'accent',
  en_route: 'accent',
  completed: 'constructive',
  cancelled: 'disabled',
}

export function getDeliveryRouteStatusBadgeProps({
  status,
}: {
  status: DeliveryRouteStatus
}): Pick<KuiBadgeProps, 'color' | 'children'> {
  return {
    color: deliveryRouteStatusToBadgeColor[status],
    children: renderEnumValue(status),
  }
}

const deliveryRouteStopStatusToBadgeColor: Record<
  DeliveryRouteStopStatus,
  KuiBadgeColor
> = {
  scheduled: 'disabled',
  arrived: 'accent',
  completed: 'constructive',
  cancelled: 'disabled',
}

export function getDeliveryRouteStopStatusBadgeProps({
  status,
}: {
  status: DeliveryRouteStopStatus
}): Pick<KuiBadgeProps, 'color' | 'children'> {
  return {
    color: deliveryRouteStopStatusToBadgeColor[status],
    children: renderEnumValue(status),
  }
}

const purchaseOrderStatusToBadgeColor: Record<
  PurchaseOrderStatus,
  KuiBadgeColor
> = {
  draft: 'disabled',
  draft__touched: 'disabled',
  pending_receipt: 'accent',
  partially_received: 'accent',
  partially_received_partially_billed: 'accent',
  pending_bill: 'accent',
  fully_billed: 'constructive',
  cancelled: 'disabled',
}

export function getPurchaseOrderStatusBadgeProps({
  status,
}: {
  status: PurchaseOrderStatus
}): Pick<KuiBadgeProps, 'color' | 'children'> {
  return {
    color: purchaseOrderStatusToBadgeColor[status],
    children: renderEnumValue(status),
  }
}

const itemReceiptStatusToBadgeColor: Record<ItemReceiptStatus, KuiBadgeColor> =
  {
    draft: 'disabled',
    received: 'constructive',
    received_and_serialized: 'constructive',
  }

export function getItemReceiptStatusBadgeProps({
  status,
}: {
  status: ItemReceiptStatus
}): Pick<KuiBadgeProps, 'color' | 'children'> {
  return {
    color: itemReceiptStatusToBadgeColor[status],
    children: status === 'draft' ? 'Draft' : 'Received',
  }
}

const assetAvailabilityStatusToBadgeColor: Record<AssetStatus, KuiBadgeColor> =
  {
    available: 'constructive',
    unavailable: 'warning',
    on_rent: 'accent',
    inactive: 'disabled',
  }

export function getAssetAvailabilityStatusBadgeProps({
  availability,
}: {
  availability: AssetStatus
}): Pick<KuiBadgeProps, 'color' | 'children'> {
  return {
    color: assetAvailabilityStatusToBadgeColor[availability],
    children: renderEnumValue(availability),
  }
}

const accountingSyncStatusToBadgeColor: Record<
  AccountingActionStatus,
  KuiBadgeColor
> = {
  not_synced: 'disabled',
  ready: 'accent',
  pending: 'accent',
  synced: 'constructive',
  failed: 'destructive',
}

export function getAccountingSyncStatusBadgeProps({
  status,
}: {
  status: AccountingActionStatus
}): Pick<KuiBadgeProps, 'color' | 'children' | 'iconType'> {
  return {
    color: accountingSyncStatusToBadgeColor[status],
    children: renderEnumValue(status),
    iconType: status === 'pending' ? 'loading_animated' : undefined,
  }
}

const warrantyStatusToBadgeColor: Record<WarrantyStatus, KuiBadgeColor> = {
  active: 'constructive',
  expired: 'destructive',
}

export function getWarrantyStatusBadgeProps({
  status,
}: {
  status: WarrantyStatus
}): Pick<KuiBadgeProps, 'color' | 'children'> {
  return {
    color: warrantyStatusToBadgeColor[status],
    children: renderEnumValue(status),
  }
}

const repairStatusToBadgeColor: Record<RepairStatus, KuiBadgeColor> = {
  draft: 'disabled',
  scheduled: 'disabled',
  in_progress: 'accent',
  working: 'accent',
  completed: 'constructive',
}

export function getRepairStatusBadgeProps({
  status,
}: {
  status: RepairStatus
}): Pick<KuiBadgeProps, 'color' | 'children'> {
  return {
    color: repairStatusToBadgeColor[status],
    children: renderEnumValue(status),
  }
}
