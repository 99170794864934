import { Center, Image } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'

import { forgotPassword } from '_autogenerated'
import logo from 'assets/logo.svg'
import {
  kuiEmailValidator,
  KuiForm,
  KuiFormSubmitButton,
  KuiFormTextInput,
  KuiLink,
  KuiPad,
  KuiStack,
} from 'components/kui'
import KyusuCard from 'components/Kyusu/KyusuCard/KyusuCard'

type ForgotPasswordFormValues = {
  email: string
}

export function ForgotPassword() {
  const form = useForm<ForgotPasswordFormValues>({
    defaultValues: { email: '' },
  })

  return (
    <Center m={{ base: 'md', sm: 'xl' }}>
      <Helmet>
        <title>Forgot password — Moab</title>
      </Helmet>

      <KyusuCard maw={420}>
        <KuiPad horizontalSize='md'>
          <Image
            src={logo}
            alt='logo'
            fit='contain'
            w='100%'
            style={{ aspectRatio: '821/171' }}
          />
        </KuiPad>

        <KuiForm
          form={form}
          render={({ formProps }) => (
            <form {...formProps}>
              <KuiStack gapSize='md'>
                <KuiFormTextInput<ForgotPasswordFormValues>
                  name='email'
                  label='Email'
                  required={true}
                  withAsterisk={false}
                  submitOnEnter={true}
                  rules={{ validate: kuiEmailValidator }}
                />

                <KuiFormSubmitButton fullWidth={true}>
                  Reset password
                </KuiFormSubmitButton>

                <KuiLink color='hushed' size='sm' to='/login'>
                  Back to login
                </KuiLink>
              </KuiStack>
            </form>
          )}
          onSubmit={onSubmit}
        />
      </KyusuCard>
    </Center>
  )

  async function onSubmit(formValues: ForgotPasswordFormValues) {
    await forgotPassword(formValues)

    notifications.show({
      title: 'Password reset',
      message: 'Check your email for instructions',
      color: 'blue',
    })
  }
}
