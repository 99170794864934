export function renderMoney(money: string | number | null | undefined): string {
  if (money === null || money === undefined || money === '') {
    return '—'
  }

  const moneyString = money.toString()

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(parseFloat(moneyString))
}

export function renderPhoneNumber(phoneNumber: string | null | undefined) {
  if (!phoneNumber) {
    return
  }

  // Remove all non-digit characters except '+'
  const cleaned = phoneNumber.replace(/[^\d+]/g, '')

  // Check if the input starts with +1 and has 11 digits, or just has 10 digits
  const match = cleaned.match(/^(\+1|1)?(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    const countryCode = match[1] ? '+1 ' : ''

    return `${countryCode}(${match[2]}) ${match[3]}-${match[4]}`
  }

  return phoneNumber // Return original input if it doesn't match expected format
}

export function getDurationInMinutes({
  start,
  end,
}: {
  start: string | Date
  end: string | Date
}) {
  const startDate = new Date(start).getTime()
  const endDate = new Date(end).getTime()
  const diffInMs = Math.abs(endDate - startDate)

  return diffInMs / (1000 * 60)
}

export function formatMinutes(minutes: string | number | null | undefined) {
  if (minutes === null || minutes === undefined || minutes === '') {
    return
  }

  const minutesNumber = parseFloat(minutes.toString())

  if (minutesNumber < 60) {
    return `${Math.round(minutesNumber)} min`
  }

  const hours = Math.floor(minutesNumber / 60)
  const remainingMinutes = Math.round(minutesNumber % 60)

  return `${hours} hr ${remainingMinutes} min`
}

// Formats a decimal percentage (e.g. 0.5 -> 50%)
export function formatPercentage(percentage: string | number) {
  return `${(parseFloat(percentage.toString()) * 100).toFixed(2).replace(/\.?0+$/, '')}%`
}

// Converts '0.5' -> '50'
export function apiDecimalToFormPercentage<
  TValue extends string | null | undefined,
>(value: TValue): TValue extends string ? string : null {
  if (!value) {
    return null as any
  }

  return (parseFloat(value) * 100).toFixed(2).replace(/\.?0+$/, '') as any
}

// Converts '50' -> '0.5'
export function formPercentageToApiDecimal<
  TValue extends string | null | undefined,
>(value: TValue): TValue extends string ? string : null {
  if (!value) {
    return null as any
  }

  return (parseFloat(value) / 100).toFixed(2) as any
}
