/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Moab Core API
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import type {
  AssetCreateSchema,
  AssetHydratedDumpSchema,
  AssetListParams,
  AssetMaintenancesDumpSchema,
  AssetOrderDumpSchema,
  AssetOrderSearchParams,
  AssetUpdateSchema,
  AssetUsageMetricBodySchema,
  AssetUsageMetricParams,
  AssetWarrantiesDumpSchema,
  AttachmentsParams,
  BaseRateProfileSchema,
  BulkAssetUpdateSchema,
  GetKlassAvailabilityParams,
  GetKlassesOrderSearchParams,
  GetKlassesParams,
  GetProductsParams,
  KlassAvailabilityDumpSchema,
  KlassCreateSchema,
  KlassHydratedDumpSchema,
  KlassOrderDetailBodySchema,
  KlassOrderListBodySchema,
  KlassOrderResponseSchema,
  KlassUpdateSchema,
  PaginatedAssetOrderSchema,
  PaginatedAssetSchema,
  PaginatedInventoryDumpSchema,
  PaginatedKlassDumpSchema,
  PaginatedKlassOrderDumpSchema,
  PaginatedProductDumpSchema,
  PaginatedRateProfileSchema,
  PaginatedRateTemplateSchema,
  PaginatedUsageMetricDumpSchema,
  PaginatedUsageMetricTypeResponseSchema,
  ProductDumpSchemaWithKlass,
  RateProfileDumpSchema,
  RateProfilesParams,
  RateTemplateCreateSchema,
  RateTemplateDumpSchema,
  RateTemplatesParams,
  SuccessResponse,
  UsageMetricTypeCreateSchema,
  UsageMetricTypeDumpSchema,
  UsageMetricTypeUpdateSchema,
  UsageMetricTypesParams
} from '../moabCoreAPI.schemas'
import { kyusuAxiosMutator } from '../../../orval/mutator/axios-mutator';
import type { ErrorType, BodyType } from '../../../orval/mutator/axios-mutator';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const attachments = (
    params: AttachmentsParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedInventoryDumpSchema>(
      {url: `/inventory/attachments`, method: 'GET',
        params, signal
    },
      options);
    }


export const getAttachmentsQueryKey = (params: AttachmentsParams,) => {
    return [`/inventory/attachments`, ...(params ? [params]: [])] as const;
    }


export const getAttachmentsQueryOptions = <TData = Awaited<ReturnType<typeof attachments>>, TError = ErrorType<unknown>>(params: AttachmentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof attachments>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAttachmentsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof attachments>>> = ({ signal }) => attachments(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof attachments>>, TError, TData> & { queryKey: QueryKey }
}

export type AttachmentsQueryResult = NonNullable<Awaited<ReturnType<typeof attachments>>>
export type AttachmentsQueryError = ErrorType<unknown>


export function useAttachments<TData = Awaited<ReturnType<typeof attachments>>, TError = ErrorType<unknown>>(
 params: AttachmentsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof attachments>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof attachments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAttachments<TData = Awaited<ReturnType<typeof attachments>>, TError = ErrorType<unknown>>(
 params: AttachmentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof attachments>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof attachments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAttachments<TData = Awaited<ReturnType<typeof attachments>>, TError = ErrorType<unknown>>(
 params: AttachmentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof attachments>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAttachments<TData = Awaited<ReturnType<typeof attachments>>, TError = ErrorType<unknown>>(
 params: AttachmentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof attachments>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAttachmentsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAttachmentsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof attachments>>, TError = ErrorType<unknown>>(params: AttachmentsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof attachments>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAttachmentsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof attachments>>> = ({ signal }) => attachments(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof attachments>>, TError, TData> & { queryKey: QueryKey }
}

export type AttachmentsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof attachments>>>
export type AttachmentsSuspenseQueryError = ErrorType<unknown>


export function useAttachmentsSuspense<TData = Awaited<ReturnType<typeof attachments>>, TError = ErrorType<unknown>>(
 params: AttachmentsParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof attachments>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAttachmentsSuspense<TData = Awaited<ReturnType<typeof attachments>>, TError = ErrorType<unknown>>(
 params: AttachmentsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof attachments>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAttachmentsSuspense<TData = Awaited<ReturnType<typeof attachments>>, TError = ErrorType<unknown>>(
 params: AttachmentsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof attachments>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAttachmentsSuspense<TData = Awaited<ReturnType<typeof attachments>>, TError = ErrorType<unknown>>(
 params: AttachmentsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof attachments>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAttachmentsSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const assetList = (
    params: AssetListParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedAssetSchema>(
      {url: `/inventory/assets`, method: 'GET',
        params, signal
    },
      options);
    }


export const getAssetListQueryKey = (params: AssetListParams,) => {
    return [`/inventory/assets`, ...(params ? [params]: [])] as const;
    }


export const getAssetListQueryOptions = <TData = Awaited<ReturnType<typeof assetList>>, TError = ErrorType<unknown>>(params: AssetListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAssetListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof assetList>>> = ({ signal }) => assetList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof assetList>>, TError, TData> & { queryKey: QueryKey }
}

export type AssetListQueryResult = NonNullable<Awaited<ReturnType<typeof assetList>>>
export type AssetListQueryError = ErrorType<unknown>


export function useAssetList<TData = Awaited<ReturnType<typeof assetList>>, TError = ErrorType<unknown>>(
 params: AssetListParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetList>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof assetList>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAssetList<TData = Awaited<ReturnType<typeof assetList>>, TError = ErrorType<unknown>>(
 params: AssetListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetList>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof assetList>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAssetList<TData = Awaited<ReturnType<typeof assetList>>, TError = ErrorType<unknown>>(
 params: AssetListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAssetList<TData = Awaited<ReturnType<typeof assetList>>, TError = ErrorType<unknown>>(
 params: AssetListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAssetListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAssetListSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof assetList>>, TError = ErrorType<unknown>>(params: AssetListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof assetList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAssetListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof assetList>>> = ({ signal }) => assetList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof assetList>>, TError, TData> & { queryKey: QueryKey }
}

export type AssetListSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof assetList>>>
export type AssetListSuspenseQueryError = ErrorType<unknown>


export function useAssetListSuspense<TData = Awaited<ReturnType<typeof assetList>>, TError = ErrorType<unknown>>(
 params: AssetListParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof assetList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAssetListSuspense<TData = Awaited<ReturnType<typeof assetList>>, TError = ErrorType<unknown>>(
 params: AssetListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof assetList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAssetListSuspense<TData = Awaited<ReturnType<typeof assetList>>, TError = ErrorType<unknown>>(
 params: AssetListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof assetList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAssetListSuspense<TData = Awaited<ReturnType<typeof assetList>>, TError = ErrorType<unknown>>(
 params: AssetListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof assetList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAssetListSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const assetCreate = (
    assetCreateSchema: BodyType<AssetCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<AssetHydratedDumpSchema>(
      {url: `/inventory/assets`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: assetCreateSchema
    },
      options);
    }


export const assetGet = (
    assetId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<AssetHydratedDumpSchema>(
      {url: `/inventory/assets/${assetId}`, method: 'GET', signal
    },
      options);
    }


export const getAssetGetQueryKey = (assetId: string,) => {
    return [`/inventory/assets/${assetId}`] as const;
    }


export const getAssetGetQueryOptions = <TData = Awaited<ReturnType<typeof assetGet>>, TError = ErrorType<unknown>>(assetId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAssetGetQueryKey(assetId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof assetGet>>> = ({ signal }) => assetGet(assetId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(assetId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof assetGet>>, TError, TData> & { queryKey: QueryKey }
}

export type AssetGetQueryResult = NonNullable<Awaited<ReturnType<typeof assetGet>>>
export type AssetGetQueryError = ErrorType<unknown>


export function useAssetGet<TData = Awaited<ReturnType<typeof assetGet>>, TError = ErrorType<unknown>>(
 assetId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof assetGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAssetGet<TData = Awaited<ReturnType<typeof assetGet>>, TError = ErrorType<unknown>>(
 assetId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof assetGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAssetGet<TData = Awaited<ReturnType<typeof assetGet>>, TError = ErrorType<unknown>>(
 assetId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAssetGet<TData = Awaited<ReturnType<typeof assetGet>>, TError = ErrorType<unknown>>(
 assetId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAssetGetQueryOptions(assetId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAssetGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof assetGet>>, TError = ErrorType<unknown>>(assetId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof assetGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAssetGetQueryKey(assetId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof assetGet>>> = ({ signal }) => assetGet(assetId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(assetId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof assetGet>>, TError, TData> & { queryKey: QueryKey }
}

export type AssetGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof assetGet>>>
export type AssetGetSuspenseQueryError = ErrorType<unknown>


export function useAssetGetSuspense<TData = Awaited<ReturnType<typeof assetGet>>, TError = ErrorType<unknown>>(
 assetId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof assetGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAssetGetSuspense<TData = Awaited<ReturnType<typeof assetGet>>, TError = ErrorType<unknown>>(
 assetId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof assetGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAssetGetSuspense<TData = Awaited<ReturnType<typeof assetGet>>, TError = ErrorType<unknown>>(
 assetId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof assetGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAssetGetSuspense<TData = Awaited<ReturnType<typeof assetGet>>, TError = ErrorType<unknown>>(
 assetId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof assetGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAssetGetSuspenseQueryOptions(assetId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const assetUpdate = (
    assetId: string,
    assetUpdateSchema: BodyType<AssetUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<AssetHydratedDumpSchema>(
      {url: `/inventory/assets/${assetId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: assetUpdateSchema
    },
      options);
    }


export const assetUsageMetric = (
    assetId: string,
    usageMetricConfigurationId: string,
    params?: AssetUsageMetricParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedUsageMetricDumpSchema>(
      {url: `/inventory/assets/${assetId}/usage/${usageMetricConfigurationId}`, method: 'GET',
        params, signal
    },
      options);
    }


export const getAssetUsageMetricQueryKey = (assetId: string,
    usageMetricConfigurationId: string,
    params?: AssetUsageMetricParams,) => {
    return [`/inventory/assets/${assetId}/usage/${usageMetricConfigurationId}`, ...(params ? [params]: [])] as const;
    }


export const getAssetUsageMetricQueryOptions = <TData = Awaited<ReturnType<typeof assetUsageMetric>>, TError = ErrorType<unknown>>(assetId: string,
    usageMetricConfigurationId: string,
    params?: AssetUsageMetricParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetUsageMetric>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAssetUsageMetricQueryKey(assetId,usageMetricConfigurationId,params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof assetUsageMetric>>> = ({ signal }) => assetUsageMetric(assetId,usageMetricConfigurationId,params, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(assetId && usageMetricConfigurationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof assetUsageMetric>>, TError, TData> & { queryKey: QueryKey }
}

export type AssetUsageMetricQueryResult = NonNullable<Awaited<ReturnType<typeof assetUsageMetric>>>
export type AssetUsageMetricQueryError = ErrorType<unknown>


export function useAssetUsageMetric<TData = Awaited<ReturnType<typeof assetUsageMetric>>, TError = ErrorType<unknown>>(
 assetId: string,
    usageMetricConfigurationId: string,
    params: undefined |  AssetUsageMetricParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetUsageMetric>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof assetUsageMetric>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAssetUsageMetric<TData = Awaited<ReturnType<typeof assetUsageMetric>>, TError = ErrorType<unknown>>(
 assetId: string,
    usageMetricConfigurationId: string,
    params?: AssetUsageMetricParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetUsageMetric>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof assetUsageMetric>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAssetUsageMetric<TData = Awaited<ReturnType<typeof assetUsageMetric>>, TError = ErrorType<unknown>>(
 assetId: string,
    usageMetricConfigurationId: string,
    params?: AssetUsageMetricParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetUsageMetric>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAssetUsageMetric<TData = Awaited<ReturnType<typeof assetUsageMetric>>, TError = ErrorType<unknown>>(
 assetId: string,
    usageMetricConfigurationId: string,
    params?: AssetUsageMetricParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetUsageMetric>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAssetUsageMetricQueryOptions(assetId,usageMetricConfigurationId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAssetUsageMetricSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof assetUsageMetric>>, TError = ErrorType<unknown>>(assetId: string,
    usageMetricConfigurationId: string,
    params?: AssetUsageMetricParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof assetUsageMetric>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAssetUsageMetricQueryKey(assetId,usageMetricConfigurationId,params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof assetUsageMetric>>> = ({ signal }) => assetUsageMetric(assetId,usageMetricConfigurationId,params, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(assetId && usageMetricConfigurationId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof assetUsageMetric>>, TError, TData> & { queryKey: QueryKey }
}

export type AssetUsageMetricSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof assetUsageMetric>>>
export type AssetUsageMetricSuspenseQueryError = ErrorType<unknown>


export function useAssetUsageMetricSuspense<TData = Awaited<ReturnType<typeof assetUsageMetric>>, TError = ErrorType<unknown>>(
 assetId: string,
    usageMetricConfigurationId: string,
    params: undefined |  AssetUsageMetricParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof assetUsageMetric>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAssetUsageMetricSuspense<TData = Awaited<ReturnType<typeof assetUsageMetric>>, TError = ErrorType<unknown>>(
 assetId: string,
    usageMetricConfigurationId: string,
    params?: AssetUsageMetricParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof assetUsageMetric>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAssetUsageMetricSuspense<TData = Awaited<ReturnType<typeof assetUsageMetric>>, TError = ErrorType<unknown>>(
 assetId: string,
    usageMetricConfigurationId: string,
    params?: AssetUsageMetricParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof assetUsageMetric>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAssetUsageMetricSuspense<TData = Awaited<ReturnType<typeof assetUsageMetric>>, TError = ErrorType<unknown>>(
 assetId: string,
    usageMetricConfigurationId: string,
    params?: AssetUsageMetricParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof assetUsageMetric>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAssetUsageMetricSuspenseQueryOptions(assetId,usageMetricConfigurationId,params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const serializedAssetListOrder = (
    assetOrderSearchParams: BodyType<AssetOrderSearchParams>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<PaginatedAssetOrderSchema>(
      {url: `/inventory/assets/serialized-assets/order`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: assetOrderSearchParams
    },
      options);
    }


export const assetGetOrder = (
    assetId: string,
    assetOrderSearchParams: BodyType<AssetOrderSearchParams>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<AssetOrderDumpSchema>(
      {url: `/inventory/assets/${assetId}/order`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: assetOrderSearchParams
    },
      options);
    }


export const getScheduledMaintenanceAsset = (
    assetId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<AssetMaintenancesDumpSchema>(
      {url: `/inventory/assets/${assetId}/scheduled-maintenance`, method: 'GET', signal
    },
      options);
    }


export const getGetScheduledMaintenanceAssetQueryKey = (assetId: string,) => {
    return [`/inventory/assets/${assetId}/scheduled-maintenance`] as const;
    }


export const getGetScheduledMaintenanceAssetQueryOptions = <TData = Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>, TError = ErrorType<unknown>>(assetId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetScheduledMaintenanceAssetQueryKey(assetId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>> = ({ signal }) => getScheduledMaintenanceAsset(assetId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(assetId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>, TError, TData> & { queryKey: QueryKey }
}

export type GetScheduledMaintenanceAssetQueryResult = NonNullable<Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>>
export type GetScheduledMaintenanceAssetQueryError = ErrorType<unknown>


export function useGetScheduledMaintenanceAsset<TData = Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>, TError = ErrorType<unknown>>(
 assetId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetScheduledMaintenanceAsset<TData = Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>, TError = ErrorType<unknown>>(
 assetId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetScheduledMaintenanceAsset<TData = Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>, TError = ErrorType<unknown>>(
 assetId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetScheduledMaintenanceAsset<TData = Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>, TError = ErrorType<unknown>>(
 assetId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetScheduledMaintenanceAssetQueryOptions(assetId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetScheduledMaintenanceAssetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>, TError = ErrorType<unknown>>(assetId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetScheduledMaintenanceAssetQueryKey(assetId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>> = ({ signal }) => getScheduledMaintenanceAsset(assetId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(assetId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>, TError, TData> & { queryKey: QueryKey }
}

export type GetScheduledMaintenanceAssetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>>
export type GetScheduledMaintenanceAssetSuspenseQueryError = ErrorType<unknown>


export function useGetScheduledMaintenanceAssetSuspense<TData = Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>, TError = ErrorType<unknown>>(
 assetId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetScheduledMaintenanceAssetSuspense<TData = Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>, TError = ErrorType<unknown>>(
 assetId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetScheduledMaintenanceAssetSuspense<TData = Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>, TError = ErrorType<unknown>>(
 assetId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetScheduledMaintenanceAssetSuspense<TData = Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>, TError = ErrorType<unknown>>(
 assetId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getScheduledMaintenanceAsset>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetScheduledMaintenanceAssetSuspenseQueryOptions(assetId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAssetWarranties = (
    assetId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<AssetWarrantiesDumpSchema>(
      {url: `/inventory/assets/${assetId}/warranties`, method: 'GET', signal
    },
      options);
    }


export const getGetAssetWarrantiesQueryKey = (assetId: string,) => {
    return [`/inventory/assets/${assetId}/warranties`] as const;
    }


export const getGetAssetWarrantiesQueryOptions = <TData = Awaited<ReturnType<typeof getAssetWarranties>>, TError = ErrorType<unknown>>(assetId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssetWarranties>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAssetWarrantiesQueryKey(assetId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssetWarranties>>> = ({ signal }) => getAssetWarranties(assetId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(assetId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAssetWarranties>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAssetWarrantiesQueryResult = NonNullable<Awaited<ReturnType<typeof getAssetWarranties>>>
export type GetAssetWarrantiesQueryError = ErrorType<unknown>


export function useGetAssetWarranties<TData = Awaited<ReturnType<typeof getAssetWarranties>>, TError = ErrorType<unknown>>(
 assetId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssetWarranties>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAssetWarranties>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetAssetWarranties<TData = Awaited<ReturnType<typeof getAssetWarranties>>, TError = ErrorType<unknown>>(
 assetId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssetWarranties>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAssetWarranties>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetAssetWarranties<TData = Awaited<ReturnType<typeof getAssetWarranties>>, TError = ErrorType<unknown>>(
 assetId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssetWarranties>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetAssetWarranties<TData = Awaited<ReturnType<typeof getAssetWarranties>>, TError = ErrorType<unknown>>(
 assetId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssetWarranties>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetAssetWarrantiesQueryOptions(assetId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAssetWarrantiesSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getAssetWarranties>>, TError = ErrorType<unknown>>(assetId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAssetWarranties>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAssetWarrantiesQueryKey(assetId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssetWarranties>>> = ({ signal }) => getAssetWarranties(assetId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(assetId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAssetWarranties>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAssetWarrantiesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getAssetWarranties>>>
export type GetAssetWarrantiesSuspenseQueryError = ErrorType<unknown>


export function useGetAssetWarrantiesSuspense<TData = Awaited<ReturnType<typeof getAssetWarranties>>, TError = ErrorType<unknown>>(
 assetId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAssetWarranties>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetAssetWarrantiesSuspense<TData = Awaited<ReturnType<typeof getAssetWarranties>>, TError = ErrorType<unknown>>(
 assetId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAssetWarranties>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetAssetWarrantiesSuspense<TData = Awaited<ReturnType<typeof getAssetWarranties>>, TError = ErrorType<unknown>>(
 assetId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAssetWarranties>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetAssetWarrantiesSuspense<TData = Awaited<ReturnType<typeof getAssetWarranties>>, TError = ErrorType<unknown>>(
 assetId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAssetWarranties>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetAssetWarrantiesSuspenseQueryOptions(assetId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const bulkAssetUpdate = (
    assetId: string,
    bulkAssetUpdateSchema: BodyType<BulkAssetUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<AssetHydratedDumpSchema>(
      {url: `/inventory/assets/bulk/${assetId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: bulkAssetUpdateSchema
    },
      options);
    }


export const assetRecordUsage = (
    assetId: string,
    assetUsageMetricBodySchema: BodyType<AssetUsageMetricBodySchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<AssetHydratedDumpSchema>(
      {url: `/inventory/assets/${assetId}/usage`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: assetUsageMetricBodySchema
    },
      options);
    }


export const getKlasses = (
    params?: GetKlassesParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedKlassDumpSchema>(
      {url: `/inventory/klasses`, method: 'GET',
        params, signal
    },
      options);
    }


export const getGetKlassesQueryKey = (params?: GetKlassesParams,) => {
    return [`/inventory/klasses`, ...(params ? [params]: [])] as const;
    }


export const getGetKlassesQueryOptions = <TData = Awaited<ReturnType<typeof getKlasses>>, TError = ErrorType<unknown>>(params?: GetKlassesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getKlasses>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetKlassesQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getKlasses>>> = ({ signal }) => getKlasses(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getKlasses>>, TError, TData> & { queryKey: QueryKey }
}

export type GetKlassesQueryResult = NonNullable<Awaited<ReturnType<typeof getKlasses>>>
export type GetKlassesQueryError = ErrorType<unknown>


export function useGetKlasses<TData = Awaited<ReturnType<typeof getKlasses>>, TError = ErrorType<unknown>>(
 params: undefined |  GetKlassesParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getKlasses>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getKlasses>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetKlasses<TData = Awaited<ReturnType<typeof getKlasses>>, TError = ErrorType<unknown>>(
 params?: GetKlassesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getKlasses>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getKlasses>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetKlasses<TData = Awaited<ReturnType<typeof getKlasses>>, TError = ErrorType<unknown>>(
 params?: GetKlassesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getKlasses>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetKlasses<TData = Awaited<ReturnType<typeof getKlasses>>, TError = ErrorType<unknown>>(
 params?: GetKlassesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getKlasses>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetKlassesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetKlassesSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getKlasses>>, TError = ErrorType<unknown>>(params?: GetKlassesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getKlasses>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetKlassesQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getKlasses>>> = ({ signal }) => getKlasses(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getKlasses>>, TError, TData> & { queryKey: QueryKey }
}

export type GetKlassesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getKlasses>>>
export type GetKlassesSuspenseQueryError = ErrorType<unknown>


export function useGetKlassesSuspense<TData = Awaited<ReturnType<typeof getKlasses>>, TError = ErrorType<unknown>>(
 params: undefined |  GetKlassesParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getKlasses>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetKlassesSuspense<TData = Awaited<ReturnType<typeof getKlasses>>, TError = ErrorType<unknown>>(
 params?: GetKlassesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getKlasses>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetKlassesSuspense<TData = Awaited<ReturnType<typeof getKlasses>>, TError = ErrorType<unknown>>(
 params?: GetKlassesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getKlasses>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetKlassesSuspense<TData = Awaited<ReturnType<typeof getKlasses>>, TError = ErrorType<unknown>>(
 params?: GetKlassesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getKlasses>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetKlassesSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const createKlass = (
    klassCreateSchema: BodyType<KlassCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<KlassHydratedDumpSchema>(
      {url: `/inventory/klasses`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: klassCreateSchema
    },
      options);
    }


export const getKlass = (
    klassId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<KlassHydratedDumpSchema>(
      {url: `/inventory/klasses/${klassId}`, method: 'GET', signal
    },
      options);
    }


export const getGetKlassQueryKey = (klassId: string,) => {
    return [`/inventory/klasses/${klassId}`] as const;
    }


export const getGetKlassQueryOptions = <TData = Awaited<ReturnType<typeof getKlass>>, TError = ErrorType<unknown>>(klassId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getKlass>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetKlassQueryKey(klassId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getKlass>>> = ({ signal }) => getKlass(klassId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(klassId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getKlass>>, TError, TData> & { queryKey: QueryKey }
}

export type GetKlassQueryResult = NonNullable<Awaited<ReturnType<typeof getKlass>>>
export type GetKlassQueryError = ErrorType<unknown>


export function useGetKlass<TData = Awaited<ReturnType<typeof getKlass>>, TError = ErrorType<unknown>>(
 klassId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getKlass>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getKlass>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetKlass<TData = Awaited<ReturnType<typeof getKlass>>, TError = ErrorType<unknown>>(
 klassId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getKlass>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getKlass>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetKlass<TData = Awaited<ReturnType<typeof getKlass>>, TError = ErrorType<unknown>>(
 klassId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getKlass>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetKlass<TData = Awaited<ReturnType<typeof getKlass>>, TError = ErrorType<unknown>>(
 klassId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getKlass>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetKlassQueryOptions(klassId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetKlassSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getKlass>>, TError = ErrorType<unknown>>(klassId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getKlass>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetKlassQueryKey(klassId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getKlass>>> = ({ signal }) => getKlass(klassId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(klassId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getKlass>>, TError, TData> & { queryKey: QueryKey }
}

export type GetKlassSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getKlass>>>
export type GetKlassSuspenseQueryError = ErrorType<unknown>


export function useGetKlassSuspense<TData = Awaited<ReturnType<typeof getKlass>>, TError = ErrorType<unknown>>(
 klassId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getKlass>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetKlassSuspense<TData = Awaited<ReturnType<typeof getKlass>>, TError = ErrorType<unknown>>(
 klassId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getKlass>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetKlassSuspense<TData = Awaited<ReturnType<typeof getKlass>>, TError = ErrorType<unknown>>(
 klassId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getKlass>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetKlassSuspense<TData = Awaited<ReturnType<typeof getKlass>>, TError = ErrorType<unknown>>(
 klassId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getKlass>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetKlassSuspenseQueryOptions(klassId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const updateKlass = (
    klassId: string,
    klassUpdateSchema: BodyType<KlassUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<KlassHydratedDumpSchema>(
      {url: `/inventory/klasses/${klassId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: klassUpdateSchema
    },
      options);
    }


export const getKlassAvailability = (
    klassId: string,
    params?: GetKlassAvailabilityParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<KlassAvailabilityDumpSchema>(
      {url: `/inventory/klasses/${klassId}/availability`, method: 'GET',
        params, signal
    },
      options);
    }


export const getGetKlassAvailabilityQueryKey = (klassId: string,
    params?: GetKlassAvailabilityParams,) => {
    return [`/inventory/klasses/${klassId}/availability`, ...(params ? [params]: [])] as const;
    }


export const getGetKlassAvailabilityQueryOptions = <TData = Awaited<ReturnType<typeof getKlassAvailability>>, TError = ErrorType<unknown>>(klassId: string,
    params?: GetKlassAvailabilityParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getKlassAvailability>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetKlassAvailabilityQueryKey(klassId,params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getKlassAvailability>>> = ({ signal }) => getKlassAvailability(klassId,params, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(klassId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getKlassAvailability>>, TError, TData> & { queryKey: QueryKey }
}

export type GetKlassAvailabilityQueryResult = NonNullable<Awaited<ReturnType<typeof getKlassAvailability>>>
export type GetKlassAvailabilityQueryError = ErrorType<unknown>


export function useGetKlassAvailability<TData = Awaited<ReturnType<typeof getKlassAvailability>>, TError = ErrorType<unknown>>(
 klassId: string,
    params: undefined |  GetKlassAvailabilityParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getKlassAvailability>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getKlassAvailability>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetKlassAvailability<TData = Awaited<ReturnType<typeof getKlassAvailability>>, TError = ErrorType<unknown>>(
 klassId: string,
    params?: GetKlassAvailabilityParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getKlassAvailability>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getKlassAvailability>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetKlassAvailability<TData = Awaited<ReturnType<typeof getKlassAvailability>>, TError = ErrorType<unknown>>(
 klassId: string,
    params?: GetKlassAvailabilityParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getKlassAvailability>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetKlassAvailability<TData = Awaited<ReturnType<typeof getKlassAvailability>>, TError = ErrorType<unknown>>(
 klassId: string,
    params?: GetKlassAvailabilityParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getKlassAvailability>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetKlassAvailabilityQueryOptions(klassId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetKlassAvailabilitySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getKlassAvailability>>, TError = ErrorType<unknown>>(klassId: string,
    params?: GetKlassAvailabilityParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getKlassAvailability>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetKlassAvailabilityQueryKey(klassId,params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getKlassAvailability>>> = ({ signal }) => getKlassAvailability(klassId,params, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(klassId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getKlassAvailability>>, TError, TData> & { queryKey: QueryKey }
}

export type GetKlassAvailabilitySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getKlassAvailability>>>
export type GetKlassAvailabilitySuspenseQueryError = ErrorType<unknown>


export function useGetKlassAvailabilitySuspense<TData = Awaited<ReturnType<typeof getKlassAvailability>>, TError = ErrorType<unknown>>(
 klassId: string,
    params: undefined |  GetKlassAvailabilityParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getKlassAvailability>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetKlassAvailabilitySuspense<TData = Awaited<ReturnType<typeof getKlassAvailability>>, TError = ErrorType<unknown>>(
 klassId: string,
    params?: GetKlassAvailabilityParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getKlassAvailability>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetKlassAvailabilitySuspense<TData = Awaited<ReturnType<typeof getKlassAvailability>>, TError = ErrorType<unknown>>(
 klassId: string,
    params?: GetKlassAvailabilityParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getKlassAvailability>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetKlassAvailabilitySuspense<TData = Awaited<ReturnType<typeof getKlassAvailability>>, TError = ErrorType<unknown>>(
 klassId: string,
    params?: GetKlassAvailabilityParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getKlassAvailability>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetKlassAvailabilitySuspenseQueryOptions(klassId,params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getKlassOrder = (
    klassId: string,
    klassOrderDetailBodySchema: BodyType<KlassOrderDetailBodySchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<KlassOrderResponseSchema>(
      {url: `/inventory/klasses/${klassId}/order`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: klassOrderDetailBodySchema
    },
      options);
    }


export const getKlassesOrderSearch = (
    klassOrderListBodySchema: BodyType<KlassOrderListBodySchema>,
    params?: GetKlassesOrderSearchParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<PaginatedKlassOrderDumpSchema>(
      {url: `/inventory/klasses/order-search`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: klassOrderListBodySchema,
        params
    },
      options);
    }


export const usageMetricTypes = (
    params?: UsageMetricTypesParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedUsageMetricTypeResponseSchema>(
      {url: `/inventory/usage-metric-types`, method: 'GET',
        params, signal
    },
      options);
    }


export const getUsageMetricTypesQueryKey = (params?: UsageMetricTypesParams,) => {
    return [`/inventory/usage-metric-types`, ...(params ? [params]: [])] as const;
    }


export const getUsageMetricTypesQueryOptions = <TData = Awaited<ReturnType<typeof usageMetricTypes>>, TError = ErrorType<unknown>>(params?: UsageMetricTypesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof usageMetricTypes>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsageMetricTypesQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof usageMetricTypes>>> = ({ signal }) => usageMetricTypes(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof usageMetricTypes>>, TError, TData> & { queryKey: QueryKey }
}

export type UsageMetricTypesQueryResult = NonNullable<Awaited<ReturnType<typeof usageMetricTypes>>>
export type UsageMetricTypesQueryError = ErrorType<unknown>


export function useUsageMetricTypes<TData = Awaited<ReturnType<typeof usageMetricTypes>>, TError = ErrorType<unknown>>(
 params: undefined |  UsageMetricTypesParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof usageMetricTypes>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof usageMetricTypes>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useUsageMetricTypes<TData = Awaited<ReturnType<typeof usageMetricTypes>>, TError = ErrorType<unknown>>(
 params?: UsageMetricTypesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof usageMetricTypes>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof usageMetricTypes>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useUsageMetricTypes<TData = Awaited<ReturnType<typeof usageMetricTypes>>, TError = ErrorType<unknown>>(
 params?: UsageMetricTypesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof usageMetricTypes>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useUsageMetricTypes<TData = Awaited<ReturnType<typeof usageMetricTypes>>, TError = ErrorType<unknown>>(
 params?: UsageMetricTypesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof usageMetricTypes>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getUsageMetricTypesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getUsageMetricTypesSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof usageMetricTypes>>, TError = ErrorType<unknown>>(params?: UsageMetricTypesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof usageMetricTypes>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsageMetricTypesQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof usageMetricTypes>>> = ({ signal }) => usageMetricTypes(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof usageMetricTypes>>, TError, TData> & { queryKey: QueryKey }
}

export type UsageMetricTypesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof usageMetricTypes>>>
export type UsageMetricTypesSuspenseQueryError = ErrorType<unknown>


export function useUsageMetricTypesSuspense<TData = Awaited<ReturnType<typeof usageMetricTypes>>, TError = ErrorType<unknown>>(
 params: undefined |  UsageMetricTypesParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof usageMetricTypes>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useUsageMetricTypesSuspense<TData = Awaited<ReturnType<typeof usageMetricTypes>>, TError = ErrorType<unknown>>(
 params?: UsageMetricTypesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof usageMetricTypes>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useUsageMetricTypesSuspense<TData = Awaited<ReturnType<typeof usageMetricTypes>>, TError = ErrorType<unknown>>(
 params?: UsageMetricTypesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof usageMetricTypes>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useUsageMetricTypesSuspense<TData = Awaited<ReturnType<typeof usageMetricTypes>>, TError = ErrorType<unknown>>(
 params?: UsageMetricTypesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof usageMetricTypes>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getUsageMetricTypesSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const usageMetricTypeCreate = (
    usageMetricTypeCreateSchema: BodyType<UsageMetricTypeCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<UsageMetricTypeDumpSchema>(
      {url: `/inventory/usage-metric-types`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: usageMetricTypeCreateSchema
    },
      options);
    }


export const usageMetricTypeGet = (
    usageMetricTypeId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<UsageMetricTypeDumpSchema>(
      {url: `/inventory/usage-metric-types/${usageMetricTypeId}`, method: 'GET', signal
    },
      options);
    }


export const getUsageMetricTypeGetQueryKey = (usageMetricTypeId: string,) => {
    return [`/inventory/usage-metric-types/${usageMetricTypeId}`] as const;
    }


export const getUsageMetricTypeGetQueryOptions = <TData = Awaited<ReturnType<typeof usageMetricTypeGet>>, TError = ErrorType<unknown>>(usageMetricTypeId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof usageMetricTypeGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsageMetricTypeGetQueryKey(usageMetricTypeId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof usageMetricTypeGet>>> = ({ signal }) => usageMetricTypeGet(usageMetricTypeId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(usageMetricTypeId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof usageMetricTypeGet>>, TError, TData> & { queryKey: QueryKey }
}

export type UsageMetricTypeGetQueryResult = NonNullable<Awaited<ReturnType<typeof usageMetricTypeGet>>>
export type UsageMetricTypeGetQueryError = ErrorType<unknown>


export function useUsageMetricTypeGet<TData = Awaited<ReturnType<typeof usageMetricTypeGet>>, TError = ErrorType<unknown>>(
 usageMetricTypeId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof usageMetricTypeGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof usageMetricTypeGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useUsageMetricTypeGet<TData = Awaited<ReturnType<typeof usageMetricTypeGet>>, TError = ErrorType<unknown>>(
 usageMetricTypeId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof usageMetricTypeGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof usageMetricTypeGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useUsageMetricTypeGet<TData = Awaited<ReturnType<typeof usageMetricTypeGet>>, TError = ErrorType<unknown>>(
 usageMetricTypeId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof usageMetricTypeGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useUsageMetricTypeGet<TData = Awaited<ReturnType<typeof usageMetricTypeGet>>, TError = ErrorType<unknown>>(
 usageMetricTypeId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof usageMetricTypeGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getUsageMetricTypeGetQueryOptions(usageMetricTypeId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getUsageMetricTypeGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof usageMetricTypeGet>>, TError = ErrorType<unknown>>(usageMetricTypeId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof usageMetricTypeGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsageMetricTypeGetQueryKey(usageMetricTypeId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof usageMetricTypeGet>>> = ({ signal }) => usageMetricTypeGet(usageMetricTypeId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(usageMetricTypeId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof usageMetricTypeGet>>, TError, TData> & { queryKey: QueryKey }
}

export type UsageMetricTypeGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof usageMetricTypeGet>>>
export type UsageMetricTypeGetSuspenseQueryError = ErrorType<unknown>


export function useUsageMetricTypeGetSuspense<TData = Awaited<ReturnType<typeof usageMetricTypeGet>>, TError = ErrorType<unknown>>(
 usageMetricTypeId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof usageMetricTypeGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useUsageMetricTypeGetSuspense<TData = Awaited<ReturnType<typeof usageMetricTypeGet>>, TError = ErrorType<unknown>>(
 usageMetricTypeId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof usageMetricTypeGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useUsageMetricTypeGetSuspense<TData = Awaited<ReturnType<typeof usageMetricTypeGet>>, TError = ErrorType<unknown>>(
 usageMetricTypeId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof usageMetricTypeGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useUsageMetricTypeGetSuspense<TData = Awaited<ReturnType<typeof usageMetricTypeGet>>, TError = ErrorType<unknown>>(
 usageMetricTypeId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof usageMetricTypeGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getUsageMetricTypeGetSuspenseQueryOptions(usageMetricTypeId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const usageMetricTypeUpdate = (
    usageMetricTypeId: string,
    usageMetricTypeUpdateSchema: BodyType<UsageMetricTypeUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<UsageMetricTypeDumpSchema>(
      {url: `/inventory/usage-metric-types/${usageMetricTypeId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: usageMetricTypeUpdateSchema
    },
      options);
    }


export const usageMetricTypeDelete = (
    usageMetricTypeId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SuccessResponse>(
      {url: `/inventory/usage-metric-types/${usageMetricTypeId}`, method: 'DELETE'
    },
      options);
    }


export const rateProfiles = (
    params?: RateProfilesParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedRateProfileSchema>(
      {url: `/inventory/rate-profiles`, method: 'GET',
        params, signal
    },
      options);
    }


export const getRateProfilesQueryKey = (params?: RateProfilesParams,) => {
    return [`/inventory/rate-profiles`, ...(params ? [params]: [])] as const;
    }


export const getRateProfilesQueryOptions = <TData = Awaited<ReturnType<typeof rateProfiles>>, TError = ErrorType<unknown>>(params?: RateProfilesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof rateProfiles>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRateProfilesQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof rateProfiles>>> = ({ signal }) => rateProfiles(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof rateProfiles>>, TError, TData> & { queryKey: QueryKey }
}

export type RateProfilesQueryResult = NonNullable<Awaited<ReturnType<typeof rateProfiles>>>
export type RateProfilesQueryError = ErrorType<unknown>


export function useRateProfiles<TData = Awaited<ReturnType<typeof rateProfiles>>, TError = ErrorType<unknown>>(
 params: undefined |  RateProfilesParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof rateProfiles>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof rateProfiles>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useRateProfiles<TData = Awaited<ReturnType<typeof rateProfiles>>, TError = ErrorType<unknown>>(
 params?: RateProfilesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof rateProfiles>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof rateProfiles>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useRateProfiles<TData = Awaited<ReturnType<typeof rateProfiles>>, TError = ErrorType<unknown>>(
 params?: RateProfilesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof rateProfiles>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useRateProfiles<TData = Awaited<ReturnType<typeof rateProfiles>>, TError = ErrorType<unknown>>(
 params?: RateProfilesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof rateProfiles>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getRateProfilesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getRateProfilesSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof rateProfiles>>, TError = ErrorType<unknown>>(params?: RateProfilesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof rateProfiles>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRateProfilesQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof rateProfiles>>> = ({ signal }) => rateProfiles(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof rateProfiles>>, TError, TData> & { queryKey: QueryKey }
}

export type RateProfilesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof rateProfiles>>>
export type RateProfilesSuspenseQueryError = ErrorType<unknown>


export function useRateProfilesSuspense<TData = Awaited<ReturnType<typeof rateProfiles>>, TError = ErrorType<unknown>>(
 params: undefined |  RateProfilesParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof rateProfiles>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useRateProfilesSuspense<TData = Awaited<ReturnType<typeof rateProfiles>>, TError = ErrorType<unknown>>(
 params?: RateProfilesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof rateProfiles>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useRateProfilesSuspense<TData = Awaited<ReturnType<typeof rateProfiles>>, TError = ErrorType<unknown>>(
 params?: RateProfilesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof rateProfiles>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useRateProfilesSuspense<TData = Awaited<ReturnType<typeof rateProfiles>>, TError = ErrorType<unknown>>(
 params?: RateProfilesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof rateProfiles>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getRateProfilesSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const rateProfileCreate = (
    baseRateProfileSchema: BodyType<BaseRateProfileSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<RateProfileDumpSchema>(
      {url: `/inventory/rate-profiles`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: baseRateProfileSchema
    },
      options);
    }


export const rateProfileGet = (
    rateProfileId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<RateProfileDumpSchema>(
      {url: `/inventory/rate-profiles/${rateProfileId}`, method: 'GET', signal
    },
      options);
    }


export const getRateProfileGetQueryKey = (rateProfileId: string,) => {
    return [`/inventory/rate-profiles/${rateProfileId}`] as const;
    }


export const getRateProfileGetQueryOptions = <TData = Awaited<ReturnType<typeof rateProfileGet>>, TError = ErrorType<unknown>>(rateProfileId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof rateProfileGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRateProfileGetQueryKey(rateProfileId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof rateProfileGet>>> = ({ signal }) => rateProfileGet(rateProfileId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(rateProfileId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof rateProfileGet>>, TError, TData> & { queryKey: QueryKey }
}

export type RateProfileGetQueryResult = NonNullable<Awaited<ReturnType<typeof rateProfileGet>>>
export type RateProfileGetQueryError = ErrorType<unknown>


export function useRateProfileGet<TData = Awaited<ReturnType<typeof rateProfileGet>>, TError = ErrorType<unknown>>(
 rateProfileId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof rateProfileGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof rateProfileGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useRateProfileGet<TData = Awaited<ReturnType<typeof rateProfileGet>>, TError = ErrorType<unknown>>(
 rateProfileId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof rateProfileGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof rateProfileGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useRateProfileGet<TData = Awaited<ReturnType<typeof rateProfileGet>>, TError = ErrorType<unknown>>(
 rateProfileId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof rateProfileGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useRateProfileGet<TData = Awaited<ReturnType<typeof rateProfileGet>>, TError = ErrorType<unknown>>(
 rateProfileId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof rateProfileGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getRateProfileGetQueryOptions(rateProfileId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getRateProfileGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof rateProfileGet>>, TError = ErrorType<unknown>>(rateProfileId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof rateProfileGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRateProfileGetQueryKey(rateProfileId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof rateProfileGet>>> = ({ signal }) => rateProfileGet(rateProfileId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(rateProfileId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof rateProfileGet>>, TError, TData> & { queryKey: QueryKey }
}

export type RateProfileGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof rateProfileGet>>>
export type RateProfileGetSuspenseQueryError = ErrorType<unknown>


export function useRateProfileGetSuspense<TData = Awaited<ReturnType<typeof rateProfileGet>>, TError = ErrorType<unknown>>(
 rateProfileId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof rateProfileGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useRateProfileGetSuspense<TData = Awaited<ReturnType<typeof rateProfileGet>>, TError = ErrorType<unknown>>(
 rateProfileId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof rateProfileGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useRateProfileGetSuspense<TData = Awaited<ReturnType<typeof rateProfileGet>>, TError = ErrorType<unknown>>(
 rateProfileId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof rateProfileGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useRateProfileGetSuspense<TData = Awaited<ReturnType<typeof rateProfileGet>>, TError = ErrorType<unknown>>(
 rateProfileId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof rateProfileGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getRateProfileGetSuspenseQueryOptions(rateProfileId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const rateProfileUpdate = (
    rateProfileId: string,
    baseRateProfileSchema: BodyType<BaseRateProfileSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<RateProfileDumpSchema>(
      {url: `/inventory/rate-profiles/${rateProfileId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: baseRateProfileSchema
    },
      options);
    }


export const rateProfileDelete = (
    rateProfileId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SuccessResponse>(
      {url: `/inventory/rate-profiles/${rateProfileId}`, method: 'DELETE'
    },
      options);
    }


export const rateTemplates = (
    params?: RateTemplatesParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedRateTemplateSchema>(
      {url: `/inventory/rate-templates`, method: 'GET',
        params, signal
    },
      options);
    }


export const getRateTemplatesQueryKey = (params?: RateTemplatesParams,) => {
    return [`/inventory/rate-templates`, ...(params ? [params]: [])] as const;
    }


export const getRateTemplatesQueryOptions = <TData = Awaited<ReturnType<typeof rateTemplates>>, TError = ErrorType<unknown>>(params?: RateTemplatesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof rateTemplates>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRateTemplatesQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof rateTemplates>>> = ({ signal }) => rateTemplates(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof rateTemplates>>, TError, TData> & { queryKey: QueryKey }
}

export type RateTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof rateTemplates>>>
export type RateTemplatesQueryError = ErrorType<unknown>


export function useRateTemplates<TData = Awaited<ReturnType<typeof rateTemplates>>, TError = ErrorType<unknown>>(
 params: undefined |  RateTemplatesParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof rateTemplates>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof rateTemplates>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useRateTemplates<TData = Awaited<ReturnType<typeof rateTemplates>>, TError = ErrorType<unknown>>(
 params?: RateTemplatesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof rateTemplates>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof rateTemplates>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useRateTemplates<TData = Awaited<ReturnType<typeof rateTemplates>>, TError = ErrorType<unknown>>(
 params?: RateTemplatesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof rateTemplates>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useRateTemplates<TData = Awaited<ReturnType<typeof rateTemplates>>, TError = ErrorType<unknown>>(
 params?: RateTemplatesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof rateTemplates>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getRateTemplatesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getRateTemplatesSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof rateTemplates>>, TError = ErrorType<unknown>>(params?: RateTemplatesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof rateTemplates>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRateTemplatesQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof rateTemplates>>> = ({ signal }) => rateTemplates(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof rateTemplates>>, TError, TData> & { queryKey: QueryKey }
}

export type RateTemplatesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof rateTemplates>>>
export type RateTemplatesSuspenseQueryError = ErrorType<unknown>


export function useRateTemplatesSuspense<TData = Awaited<ReturnType<typeof rateTemplates>>, TError = ErrorType<unknown>>(
 params: undefined |  RateTemplatesParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof rateTemplates>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useRateTemplatesSuspense<TData = Awaited<ReturnType<typeof rateTemplates>>, TError = ErrorType<unknown>>(
 params?: RateTemplatesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof rateTemplates>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useRateTemplatesSuspense<TData = Awaited<ReturnType<typeof rateTemplates>>, TError = ErrorType<unknown>>(
 params?: RateTemplatesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof rateTemplates>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useRateTemplatesSuspense<TData = Awaited<ReturnType<typeof rateTemplates>>, TError = ErrorType<unknown>>(
 params?: RateTemplatesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof rateTemplates>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getRateTemplatesSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const rateTemplateCreate = (
    rateTemplateCreateSchema: BodyType<RateTemplateCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<RateTemplateDumpSchema>(
      {url: `/inventory/rate-templates`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: rateTemplateCreateSchema
    },
      options);
    }


export const defaultRateTemplate = (

 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<RateTemplateDumpSchema>(
      {url: `/inventory/rate-templates/default`, method: 'GET', signal
    },
      options);
    }


export const getDefaultRateTemplateQueryKey = () => {
    return [`/inventory/rate-templates/default`] as const;
    }


export const getDefaultRateTemplateQueryOptions = <TData = Awaited<ReturnType<typeof defaultRateTemplate>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof defaultRateTemplate>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDefaultRateTemplateQueryKey();



    const queryFn: QueryFunction<Awaited<ReturnType<typeof defaultRateTemplate>>> = ({ signal }) => defaultRateTemplate(requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof defaultRateTemplate>>, TError, TData> & { queryKey: QueryKey }
}

export type DefaultRateTemplateQueryResult = NonNullable<Awaited<ReturnType<typeof defaultRateTemplate>>>
export type DefaultRateTemplateQueryError = ErrorType<unknown>


export function useDefaultRateTemplate<TData = Awaited<ReturnType<typeof defaultRateTemplate>>, TError = ErrorType<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof defaultRateTemplate>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof defaultRateTemplate>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useDefaultRateTemplate<TData = Awaited<ReturnType<typeof defaultRateTemplate>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof defaultRateTemplate>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof defaultRateTemplate>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useDefaultRateTemplate<TData = Awaited<ReturnType<typeof defaultRateTemplate>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof defaultRateTemplate>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useDefaultRateTemplate<TData = Awaited<ReturnType<typeof defaultRateTemplate>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof defaultRateTemplate>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getDefaultRateTemplateQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getDefaultRateTemplateSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof defaultRateTemplate>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof defaultRateTemplate>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDefaultRateTemplateQueryKey();



    const queryFn: QueryFunction<Awaited<ReturnType<typeof defaultRateTemplate>>> = ({ signal }) => defaultRateTemplate(requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof defaultRateTemplate>>, TError, TData> & { queryKey: QueryKey }
}

export type DefaultRateTemplateSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof defaultRateTemplate>>>
export type DefaultRateTemplateSuspenseQueryError = ErrorType<unknown>


export function useDefaultRateTemplateSuspense<TData = Awaited<ReturnType<typeof defaultRateTemplate>>, TError = ErrorType<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof defaultRateTemplate>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useDefaultRateTemplateSuspense<TData = Awaited<ReturnType<typeof defaultRateTemplate>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof defaultRateTemplate>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useDefaultRateTemplateSuspense<TData = Awaited<ReturnType<typeof defaultRateTemplate>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof defaultRateTemplate>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useDefaultRateTemplateSuspense<TData = Awaited<ReturnType<typeof defaultRateTemplate>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof defaultRateTemplate>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getDefaultRateTemplateSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const rateTemplateGet = (
    rateTemplateId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<RateTemplateDumpSchema>(
      {url: `/inventory/rate-templates/${rateTemplateId}`, method: 'GET', signal
    },
      options);
    }


export const getRateTemplateGetQueryKey = (rateTemplateId: string,) => {
    return [`/inventory/rate-templates/${rateTemplateId}`] as const;
    }


export const getRateTemplateGetQueryOptions = <TData = Awaited<ReturnType<typeof rateTemplateGet>>, TError = ErrorType<unknown>>(rateTemplateId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof rateTemplateGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRateTemplateGetQueryKey(rateTemplateId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof rateTemplateGet>>> = ({ signal }) => rateTemplateGet(rateTemplateId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(rateTemplateId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof rateTemplateGet>>, TError, TData> & { queryKey: QueryKey }
}

export type RateTemplateGetQueryResult = NonNullable<Awaited<ReturnType<typeof rateTemplateGet>>>
export type RateTemplateGetQueryError = ErrorType<unknown>


export function useRateTemplateGet<TData = Awaited<ReturnType<typeof rateTemplateGet>>, TError = ErrorType<unknown>>(
 rateTemplateId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof rateTemplateGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof rateTemplateGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useRateTemplateGet<TData = Awaited<ReturnType<typeof rateTemplateGet>>, TError = ErrorType<unknown>>(
 rateTemplateId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof rateTemplateGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof rateTemplateGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useRateTemplateGet<TData = Awaited<ReturnType<typeof rateTemplateGet>>, TError = ErrorType<unknown>>(
 rateTemplateId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof rateTemplateGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useRateTemplateGet<TData = Awaited<ReturnType<typeof rateTemplateGet>>, TError = ErrorType<unknown>>(
 rateTemplateId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof rateTemplateGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getRateTemplateGetQueryOptions(rateTemplateId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getRateTemplateGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof rateTemplateGet>>, TError = ErrorType<unknown>>(rateTemplateId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof rateTemplateGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRateTemplateGetQueryKey(rateTemplateId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof rateTemplateGet>>> = ({ signal }) => rateTemplateGet(rateTemplateId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(rateTemplateId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof rateTemplateGet>>, TError, TData> & { queryKey: QueryKey }
}

export type RateTemplateGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof rateTemplateGet>>>
export type RateTemplateGetSuspenseQueryError = ErrorType<unknown>


export function useRateTemplateGetSuspense<TData = Awaited<ReturnType<typeof rateTemplateGet>>, TError = ErrorType<unknown>>(
 rateTemplateId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof rateTemplateGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useRateTemplateGetSuspense<TData = Awaited<ReturnType<typeof rateTemplateGet>>, TError = ErrorType<unknown>>(
 rateTemplateId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof rateTemplateGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useRateTemplateGetSuspense<TData = Awaited<ReturnType<typeof rateTemplateGet>>, TError = ErrorType<unknown>>(
 rateTemplateId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof rateTemplateGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useRateTemplateGetSuspense<TData = Awaited<ReturnType<typeof rateTemplateGet>>, TError = ErrorType<unknown>>(
 rateTemplateId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof rateTemplateGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getRateTemplateGetSuspenseQueryOptions(rateTemplateId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const rateTemplateUpdate = (
    rateTemplateId: string,
    rateTemplateCreateSchema: BodyType<RateTemplateCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<RateTemplateDumpSchema>(
      {url: `/inventory/rate-templates/${rateTemplateId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: rateTemplateCreateSchema
    },
      options);
    }


export const rateTemplateDelete = (
    rateTemplateId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SuccessResponse>(
      {url: `/inventory/rate-templates/${rateTemplateId}`, method: 'DELETE'
    },
      options);
    }


export const getProducts = (
    params?: GetProductsParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedProductDumpSchema>(
      {url: `/inventory/products`, method: 'GET',
        params, signal
    },
      options);
    }


export const getGetProductsQueryKey = (params?: GetProductsParams,) => {
    return [`/inventory/products`, ...(params ? [params]: [])] as const;
    }


export const getGetProductsQueryOptions = <TData = Awaited<ReturnType<typeof getProducts>>, TError = ErrorType<unknown>>(params?: GetProductsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProducts>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProductsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProducts>>> = ({ signal }) => getProducts(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProducts>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProductsQueryResult = NonNullable<Awaited<ReturnType<typeof getProducts>>>
export type GetProductsQueryError = ErrorType<unknown>


export function useGetProducts<TData = Awaited<ReturnType<typeof getProducts>>, TError = ErrorType<unknown>>(
 params: undefined |  GetProductsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProducts>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getProducts>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetProducts<TData = Awaited<ReturnType<typeof getProducts>>, TError = ErrorType<unknown>>(
 params?: GetProductsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProducts>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getProducts>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetProducts<TData = Awaited<ReturnType<typeof getProducts>>, TError = ErrorType<unknown>>(
 params?: GetProductsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProducts>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetProducts<TData = Awaited<ReturnType<typeof getProducts>>, TError = ErrorType<unknown>>(
 params?: GetProductsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProducts>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetProductsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetProductsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getProducts>>, TError = ErrorType<unknown>>(params?: GetProductsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getProducts>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProductsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProducts>>> = ({ signal }) => getProducts(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getProducts>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProductsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getProducts>>>
export type GetProductsSuspenseQueryError = ErrorType<unknown>


export function useGetProductsSuspense<TData = Awaited<ReturnType<typeof getProducts>>, TError = ErrorType<unknown>>(
 params: undefined |  GetProductsParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getProducts>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetProductsSuspense<TData = Awaited<ReturnType<typeof getProducts>>, TError = ErrorType<unknown>>(
 params?: GetProductsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getProducts>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetProductsSuspense<TData = Awaited<ReturnType<typeof getProducts>>, TError = ErrorType<unknown>>(
 params?: GetProductsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getProducts>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetProductsSuspense<TData = Awaited<ReturnType<typeof getProducts>>, TError = ErrorType<unknown>>(
 params?: GetProductsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getProducts>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetProductsSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const productGet = (
    id: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<ProductDumpSchemaWithKlass>(
      {url: `/inventory/products/${id}`, method: 'GET', signal
    },
      options);
    }


export const getProductGetQueryKey = (id: string,) => {
    return [`/inventory/products/${id}`] as const;
    }


export const getProductGetQueryOptions = <TData = Awaited<ReturnType<typeof productGet>>, TError = ErrorType<unknown>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof productGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getProductGetQueryKey(id);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof productGet>>> = ({ signal }) => productGet(id, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof productGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ProductGetQueryResult = NonNullable<Awaited<ReturnType<typeof productGet>>>
export type ProductGetQueryError = ErrorType<unknown>


export function useProductGet<TData = Awaited<ReturnType<typeof productGet>>, TError = ErrorType<unknown>>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof productGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof productGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useProductGet<TData = Awaited<ReturnType<typeof productGet>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof productGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof productGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useProductGet<TData = Awaited<ReturnType<typeof productGet>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof productGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useProductGet<TData = Awaited<ReturnType<typeof productGet>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof productGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getProductGetQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getProductGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof productGet>>, TError = ErrorType<unknown>>(id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof productGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getProductGetQueryKey(id);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof productGet>>> = ({ signal }) => productGet(id, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof productGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ProductGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof productGet>>>
export type ProductGetSuspenseQueryError = ErrorType<unknown>


export function useProductGetSuspense<TData = Awaited<ReturnType<typeof productGet>>, TError = ErrorType<unknown>>(
 id: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof productGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useProductGetSuspense<TData = Awaited<ReturnType<typeof productGet>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof productGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useProductGetSuspense<TData = Awaited<ReturnType<typeof productGet>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof productGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useProductGetSuspense<TData = Awaited<ReturnType<typeof productGet>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof productGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getProductGetSuspenseQueryOptions(id,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



