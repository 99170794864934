import { type ReactNode } from 'react'
import { type FieldValues } from 'react-hook-form'
import { Radio, type RadioProps } from 'react-hook-form-mantine'

const OVERRIDES: Record<string, string> = {
  ach: 'ACH',
  reservation_creation: 'Reservation created',
  bd1_contract_start: '1 day before contract start',
  bd2_contract_start: '2 days before contract start',
  bd14_contract_start: '14 days before contract start',

  upfront: 'In advance',
  in_arrears: 'In arrears',

  quote__pending: 'Pending',
  quote__ready: 'Ready',
  quote__rejected: 'Rejected',

  hourly: 'Hour',
  two_hourly: '2 Hour',
  four_hourly: '4 Hour',
  daily: 'Day',
  two_daily: '2 Day',
  weekly: 'Week',

  twenty_eight_days: '28 Day',

  macrs: 'MACRS',

  vat: 'VAT',
  gst: 'GST',

  order__rental: 'Rental subtotal',
  order__sale: 'Sales and services subtotal',

  item__rental: 'Rental items',
  item__sale: 'Sales and service items',

  standard: 'Usage meter',
  fuel: 'Capacity gauge',

  new_hampshire: 'New Hampshire',
  new_jersey: 'New Jersey',
  new_mexico: 'New Mexico',
  new_york: 'New York',
  north_carolina: 'North Carolina',
  north_dakota: 'North Dakota',
  rhode_island: 'Rhode Island',
  south_carolina: 'South Carolina',
  south_dakota: 'South Dakota',
  west_virginia: 'West Virginia',
  district_of_columbia: 'District of Columbia',
  american_samoa: 'American Samoa',
  puerto_rico: 'Puerto Rico',

  united_states: 'United States',

  entire: 'Full contract billing',
  zero: 'Due on receipt',

  rer: 'RER',

  asset: 'Asset',
  customer: 'Customer',
  klass: 'Class',

  independent: 'Separate line item',
  merged: 'Blended into price',
  grouped: 'Grouped into an "other fees" line item',

  require: 'Applied by default',
  optional: 'Optionally applied per order',

  purchase_order__po_pdf: 'Purchase order pdf',
  draft__touched: 'Draft',

  received_and_serialized: 'Received',

  sale__fixed: 'Sale price',
  sale__markup: 'Sale markup',

  pickup_dropoff: 'Pickup and dropoff',
}

export function toProperCase(str: string): string {
  /*
   * Capitalize the first letter of each word
   * and lowercase the rest of the word
   */
  return str.replace(/\w\S*/, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
  })
}

export function renderEnumValue(val?: string | null): string {
  /*
   * Remove _id from the end of the string
   * Remove all underscores
   */
  if (!val) {
    return ''
  }

  if (OVERRIDES[val]) {
    return OVERRIDES[val]
  }

  return toProperCase(
    val.replace(/_id/g, '').replace(/_/g, ' ').replace(/ {2}/g, ' ')
  )
}

export function renderEnumChoices(
  obj: Record<string, string>,
  options?:
    | { exclude?: string[]; include?: never }
    | { include?: string[]; exclude?: never }
): { value: string; label: string }[] {
  /*
   * Render a list of choices from an enum
   */
  return Object.keys(obj)
    .filter((key) => {
      if (options?.include) {
        return options.include.includes(key)
      }

      return !options?.exclude?.includes(key)
    })
    .map((key) => ({
      value: key,
      label: renderEnumValue(key),
    }))
}

export function renderEnumRadioItems<T extends FieldValues>(
  obj: Record<string, string>,
  options: {
    props?: Partial<RadioProps<T>>
    exclude?: string[]
    mode: 'view' | 'edit' | 'create'
  }
): ReactNode {
  /*
   * Render a list of radio items from an enum
   */
  return (
    <>
      {renderEnumChoices(obj, { exclude: options.exclude }).map((choice) => (
        <Radio.Item
          key={choice.value}
          value={choice.value}
          label={choice.label}
          disabled={options.mode === 'view'}
          {...options.props}
        />
      ))}
    </>
  )
}

export function makeEnumFilterProps<T extends Record<string, string>>(
  obj: T,
  options?: {
    include?: (keyof T)[]
    exclude?: (keyof T)[]
  }
) {
  return {
    items: Object.keys(obj)
      .filter((key) => {
        if (options?.include) {
          return options.include.includes(key)
        }

        return !options?.exclude?.includes(key)
      })
      .map((key) => ({
        key,
        label: renderEnumValue(key),
      })),
    searchable: false,
  }
}
