import { lazily } from 'react-lazily'

import {
  getGetRepairHistoryQueryOptions,
  getRepairGetQueryOptions,
} from '_autogenerated'
import { repairsUrls } from 'routes/urls'
import { createRoute } from 'routing'

const {
  RepairListPage,
  RepairCreatePage,
  RepairDetailPageRoot,
  RepairDetailPageDetailsTab,
  RepairDetailPageActivityTab,
  RepairEditPage,
  RepairTechnicianWorkPage,
} = lazily(() => import('./components'))

export const repairRoutes = [
  createRoute({
    route: repairsUrls,
    Component: RepairListPage,
  }),
  createRoute({
    route: repairsUrls.create,
    Component: RepairCreatePage,
  }),
  createRoute({
    route: repairsUrls.detail,
    Component: RepairDetailPageRoot,
    prefetch: ({ id }) => [getRepairGetQueryOptions(id)],
    children: [
      createRoute({
        route: repairsUrls.detail,
        prefetch: ({ id }) => [getGetRepairHistoryQueryOptions(id)],
        Component: RepairDetailPageDetailsTab,
      }),
      createRoute({
        route: repairsUrls.detail.activity,
        Component: RepairDetailPageActivityTab,
      }),
    ],
  }),
  createRoute({
    route: repairsUrls.edit,
    Component: RepairEditPage,
    prefetch: ({ id }) => [getRepairGetQueryOptions(id)],
  }),
]

export const repairFullPageRoutes = [
  createRoute({
    route: repairsUrls.technicianWork,
    Component: RepairTechnicianWorkPage,
    fullPage: true,
    prefetch: ({ id }) => [getRepairGetQueryOptions(id)],
  }),
]
